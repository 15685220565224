import BacktestingMetricCard from "@/components/project/backtest/metrics/BacktestingMetricCard";

const MetricDisplay = (props: { benchmarks?: any, metrics: any, minWidth?: number }) => {
  const metrics = props.metrics
  const benchmarks = props.benchmarks ? props.benchmarks : []
  return (
    <div className="gap-6 flex flex-wrap">
      {
        metrics?.map((metric: any, index: any) => (
          <>
            <BacktestingMetricCard benchmarks={benchmarks[metric.name]} key={index} value={metric.value} name={metric.display_name}
                                  type={metric.type} dataType={metric.data_type} minWidth={props.minWidth}/>
          </>
        ))
      }
    </div>
  )
}

export default MetricDisplay

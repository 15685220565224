import {ButtonProps} from "@/types/buttons";
import {classNames} from "@/utils/general";

const MediumBlackButton = ({
                             click,
                             children,
                             width = 'auto',
                             additionalClasses = '',
                             disabled = false
                           }: ButtonProps) => {
  const classes = `${width === 'full' ? 'w-full' : ''} ${disabled ? "bg-gray-700" : "bg-black hover:bg-gray-800"} rounded-md py-2.5 px-6 text-sm transition duration-100 ease-in text-white ${additionalClasses}`;
  return (
    <button onClick={click} className={classes} disabled={disabled}>
      {children}
    </button>
  );
}

MediumBlackButton.defaultProps = {
  width: 'auto',
  click: () => {
  }
}

export default MediumBlackButton;

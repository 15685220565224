import {getCollection, getDoc, setDoc} from '../libs/firestore';
import {StarterModel} from "@/types/model";

function createStarterModel(uid: string, name: string, description: string, backtestId: string, modelId: string, tags: Array<string>, url: string,) {
    const data = {
        userId: uid,
        name: name,
        url: url,
        description: description,
        backtestId: backtestId,
        modelId: modelId,
        tags: tags
    }
    return setDoc(`starterModels/${modelId}`, data).then(() => {
        return modelId;
    });
}

async function getStarterModels() {
    const modelsQuery = await getCollection('/projects/starters/models');
    let models = [];
    for (const modelQuery of modelsQuery.docs) {
        const model = modelQuery.data();
        const metricsQuery = await getDoc(`/projects/starters/models/${model.modelId}/backtests/${model.backtestId}/metrics/blankly`);
        const metricsData = metricsQuery.data();

        // TODO this is a patch fix
        if (metricsData === undefined) {
            continue
        }

        const sharpe = metricsData?.metrics.sharpe.value ? metricsData?.metrics.sharpe.value : 0;
        const sortino = metricsData?.metrics.sortino.value ? metricsData?.metrics.sortino.value : 0;
        const cagr = metricsData?.metrics.cagr.value ? metricsData?.metrics.cagr.value : 0;
        const starterModel: StarterModel = {
            id: model.starterModelId,
            backtestId: model.backtestId,
            modelId: model.modelId,
            creatorUid: model.creatorId,
            name: model.name,
            description: model.description,
            labels: model.labels,
            tickers: model.tickers,
            exchange: model.exchanges,
            stats: { sortino: sortino, sharpe: sharpe, cagr: cagr }
        }
        models.push(starterModel);
    }
    return models;
}

function getStarterModel(id: string) {
    return getDoc(`/starterModels/${id}`);
}

function getBlanklyMetrics(docId: string) {
    return getDoc(`starterModels/${docId}/metrics/blankly`)
}

function getBlanklyTimeseriesMetrics(docId: string) {
    return getDoc(`starterModels/${docId}/timeseriesMetrics/blankly`)
}

export {createStarterModel, getStarterModel, getStarterModels, getBlanklyMetrics, getBlanklyTimeseriesMetrics};